import React from "react";
import { Tag, Button, DatePicker, Input, Tooltip, Descriptions, Badge, Select } from "antd";
import { message as messageApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { StickyHeaderCustomTable } from "../../components";
import { Filter } from "../../components/Design";
import { CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import { operator as operatorApi } from "../../apis";
import MessageTable from "../../components/Table/messageTable";

const STATUS = [
  { value: "NEW" },
  { value: "PENDING" },
  { value: "SUCCESS" },
  { value: "FAILED" },
  { value: "REFUNDED" }
];

const TYPE = [
  { value: "WALLET" },
  { value: "MERCHANT" }
];
export default () => {
  const myTableRef = React.useRef();
  const { accessToken } = useSelector((state) => state.auth);
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState();

  const [filters, setFilters] = React.useState({
    start_date    : "",
    end_date      : "",
    operator_code : null,
    message_status: null,
    client_type   : null
  });

  React.useEffect(() => {
    operatorApi.list({
      offset: {
        page: 1,
        limit: 100
      },
      filter: {
        is_active: true,
      }
    }).then((res) => {
      if (res) {
        let listData = [];
        for (let index = 0; index < res.rows.length; index++) {
          const selectedData = res.rows[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          selectedData.tableRef = React.createRef();
          listData.push(selectedData);
        }
        setOptions(listData);
      }
    });
  }, []);

  const dateFilter = (e)=> {
    setFilters({ ...filters, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e &&moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const dateFilter3 = (e)=> {
    setFilters({ ...filters, operator_code: e });
  };

  const dateFilter2 = (e)=> {
    setFilters({ ...filters, message_status: e });
  };

  const dateFilter4 = (e)=> {
    setFilters({ ...filters, client_type: e });
  };

  const onClear = () => {
    setFilters({
      start_date    : "",
      end_date      : "",
      operator_code : null,
      message_status: null,
      client_type   : null
    });
  };
  React.useEffect(() => {
    messageApi.list({})
      .then((e) => {
        setData(e);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);


  return (
    <PageContainer>
      <PageHeader title="Хүсэлтийн жагсаалт" />
      <PageContent>
        <Filter extra={[ <Button onClick={onClear}>Цэвэрлэх</Button> ]} children={[ <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />, <Select
          // mode="multiple"
          style={{ width: "200px" }}
          options={STATUS}
          placeholder="Төлөв"
          maxTagCount="responsive"
          value={filters.message_status}
          onChange={(val) => {
            dateFilter2(val);
          }}
          optionRender={(option) => {
            return (
              <Space>
                <span role="img" aria-label={option}>
                  {option}
                </span>
              </Space>
            );
          }}
        />, <Select
          style={{ width: "200px" }}
          options={options}
          placeholder="Оператор"
          maxTagCount="responsive"
          value={filters.operator_code}
          onChange={(val) => {
            dateFilter3(val);
          }}
          optionRender={(option) => {
            return (
              <Space>
                <span role="img" aria-label={option.regUserId}>
                  {option.regUserId}
                </span>
              </Space>
            );
          }}
        />, <Select
          style={{ width: "200px" }}
          options={TYPE}
          placeholder="Төрөл"
          maxTagCount="responsive"
          value={filters.client_type}
          onChange={(val) => {
            dateFilter4(val);
          }}
          optionRender={(option) => {
            return (
              <Space>
                <span role="img" aria-label={option}>
                  {option}
                </span>
              </Space>
            );
          }}
        />,]} >
        </Filter>
        {/* <TableContainer2>
          <Table
            pagination={false}
            ref={myTableRef}
            filters={filters}
            loadData={messageApi.list}
            rowKey={(record) => record._id}
            thead={() => (
              <thead className="ant-table-thead" style={{ zIndex: 1 }}>
                <tr>
                  <th className="ant-table-cell">№</th>
                  <th className="ant-table-cell">Харилцагч</th>
                  <th className="ant-table-cell">Төрөл</th>
                  <th className="ant-table-cell">QR код</th>
                  <th className="ant-table-cell">Хэрэглэгч</th>
                  <th className="ant-table-cell">Төлөв</th>
                  <th className="ant-table-cell">Огноо</th>
                </tr>
              </thead>
            )}
            tbody={(row, index, page, limit) => {
              let pIndex = (page * limit) + index - limit + 1;
              // const pIndex = customIndex + 1;
              const pRow = row;
              if (index < 10){
                return (
                  <>
                    <tbody key={index} className="ant-table-tbody display-none" style={{ display: "none" }}>
                      <tr>
                        <td className="ant-table-cell">{pIndex}</td>
                        <td className="ant-table-cell">
                          <div>
                            <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                            <Tag>{row.operator_code}</Tag>
                            <Tag color="black">{row.client_type}</Tag>
                          </div>
                        </td>
                        <td className="ant-table-cell">
                          <Tag>{row.msg_type}:{row.version_no}</Tag>
                        </td>
                        <td className="ant-table-cell">
                          <Input.Group compact>
                            <Input style={{ width: "calc(100% - 32px)" }} defaultValue={row.qrc_payload} />
                            <Tooltip title="Copy"><Button icon={<CopyOutlined />} /></Tooltip>
                          </Input.Group>
                        </td>
                        <td className="ant-table-cell">
                          <div>
                            <div style={{ marginBottom: 5 }}>{row.customer_name}</div>
                            <Tag>{row.customer_code}</Tag>
                          </div>
                        </td>
                        <td className="ant-table-cell">
                          <Tag>{row.message_status}</Tag>
                        </td>
                        <td className="ant-table-cell">{moment(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                      </tr>
                    </tbody>

                    {row.children.map((row, index) => {
                      return (
                        <tbody key={index} className="ant-table-tbody" style={{ background: "#fafafa" }}>
                          <tr>
                            <td className="ant-table-cell">{pIndex}.{index + 1}</td>
                            <td className="ant-table-cell">
                              <div>
                                <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                                <Tag>{row.operator_code}</Tag>
                                <Tag color="black">{row.client_type}</Tag>
                              </div>
                            </td>
                            <td className="ant-table-cell">
                              <Tag>{row.msg_type}:{row.version_no}</Tag>
                            </td>
                            <td className="ant-table-cell" style={{ padding: 0 }} colSpan={2}>
                              {(() => {
                                switch (row.msg_type) {
                                  case "QR_INQUIRY_INFO": {
                                    return (
                                      <Description bordered>
                                        <Description.Item label="Төлбөрийн дүн" span={4}>{row.amount} {row.currency}</Description.Item>
                                        <Description.Item label="Гүйлгээний утга" span={4}>{row.description}</Description.Item>
                                        <Description.Item label="Хүлээн авах данс" span={4}>
                                          <Description bordered>
                                            {row.bank_accounts.map((row, index) => {
                                              return (
                                                <Description.Item key={index} label={row.bank_code} span={4}>
                                                  <div>{row.account_name}</div>
                                                  <Tag>{row.account_number}</Tag>
                                                  {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                </Description.Item>
                                              );
                                            })}
                                          </Description>
                                        </Description.Item>
                                      </Description>
                                    );
                                  }
                                  case "QR_PAY": {
                                    return (
                                      <Description bordered>
                                        {pRow?.client_type === "MERCHANT" && (
                                          <>
                                            <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                            <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                            <Description.Item label="Хүлээн авах данс" span={4}>
                                              <Description bordered>
                                                {pRow.bank_accounts?.map((row, index) => {
                                                  return (
                                                    <Description.Item key={index} label={row.bank_code} span={4}>
                                                      <div>{row.account_name}</div>
                                                      <Tag>{row.account_number}</Tag>
                                                      {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                    </Description.Item>
                                                  );
                                                })}
                                              </Description>
                                            </Description.Item>
                                          </>
                                        )}
                                        <Description.Item label="Нэхэмжлэл дугаар" span={2}>{row.invoice_code}</Description.Item>
                                        <Description.Item label="Төлбөрийн дугаар" span={2}>{row.payment_code}</Description.Item>
                                        <Description.Item label="Төлбөрийн төрөл" span={4}>{row.payment_method}</Description.Item>
                                      </Description>
                                    );
                                  }
                                  case "QR_PAID": {
                                    if (row.client_type === "MERCHANT")
                                      return (
                                        <Description bordered>
                                          <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                            <Description bordered>
                                              <Description.Item key={index} label="VISA" span={4}>
                                                <div>{pRow.cardholder_name}</div>
                                                <Tag>{pRow.pan}</Tag>
                                                <Tag color="black">{pRow.expiry}</Tag>
                                              </Description.Item>
                                            </Description>
                                          </Description.Item>
                                        </Description>
                                      );

                                    return (
                                      <Description bordered>
                                        {pRow?.client_type === "MERCHANT" && (
                                          <>
                                            <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                            <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                            <Description.Item label="Хүлээн авах данс" span={4}>
                                              <Description bordered>
                                                {pRow.bank_accounts?.map((row, index) => {
                                                  return (
                                                    <Description.Item key={index} label={row.bank_code} span={4}>
                                                      <div>{row.account_name}</div>
                                                      <Tag>{row.account_number}</Tag>
                                                      {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                    </Description.Item>
                                                  );
                                                })}
                                              </Description>
                                            </Description.Item>
                                          </>
                                        )}
                                        <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                          <Description bordered>
                                            <Description.Item key={index} label={row.bank_code} span={4}>
                                              <div>{row.bank_account_name}</div>
                                              <Tag>{row.bank_account_number}</Tag>
                                            </Description.Item>
                                          </Description>
                                        </Description.Item>
                                      </Description>
                                    );
                                  }
                                  default:
                                }
                              })()}
                            </td>
                            <td className="ant-table-cell">
                              <Tag>{row.message_status}</Tag>
                            </td>
                            <td className="ant-table-cell">{moment(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </>
                );
              }
            }} />
        </TableContainer2> */}
        <TableContainer>
          <MessageTable
            data={data}
          />
          {/* <Table
            ref={myTableRef}
            filters={filters}
            loadData={messageApi.list}
            rowKey={(record) => record._id}
            thead={() => (
              <thead className="ant-table-thead sticky">
                <tr>
                  <th className="ant-table-cell">№</th>
                  <th className="ant-table-cell">Харилцагч</th>
                  <th className="ant-table-cell">Төрөл</th>
                  <th className="ant-table-cell">QR код</th>
                  <th className="ant-table-cell">Хэрэглэгч</th>
                  <th className="ant-table-cell">Төлөв</th>
                  <th className="ant-table-cell">Огноо</th>
                </tr>
              </thead>
            )}
            tbody={(row, index, page, limit) => {
              let pIndex = (page * limit) + index - limit + 1;
              const pRow = row;

              return (
                <>
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell">{pIndex}</td>
                      <td className="ant-table-cell">
                        <div>
                          <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                          <Tag>{row.operator_code}</Tag>
                          <Tag color="black">{row.client_type}</Tag>
                        </div>
                      </td>
                      <td className="ant-table-cell">
                        <Tag>{row.msg_type}:{row.version_no}</Tag>
                      </td>
                      <td className="ant-table-cell">
                        <Input.Group compact>
                          <Input style={{ width: "calc(100% - 32px)" }} defaultValue={row.qrc_payload} />
                          <Tooltip title="Copy"><Button icon={<CopyOutlined />} /></Tooltip>
                        </Input.Group>
                      </td>
                      <td className="ant-table-cell">
                        <div>
                          <div style={{ marginBottom: 5 }}>{row.customer_name}</div>
                          <Tag>{row.customer_code}</Tag>
                        </div>
                      </td>
                      <td className="ant-table-cell">
                        <Tag>{row.message_status}</Tag>
                      </td>
                      <td className="ant-table-cell">{moment(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                    </tr>
                  </tbody>

                  {row.children.map((row, index) => {
                    return (
                      <tbody key={index} className="ant-table-tbody" style={{ background: "#fafafa" }}>
                        <tr>
                          <td className="ant-table-cell">{pIndex}.{index + 1}</td>
                          <td className="ant-table-cell">
                            <div>
                              <div style={{ marginBottom: 5 }}>{row.operator_name}</div>
                              <Tag>{row.operator_code}</Tag>
                              <Tag color="black">{row.client_type}</Tag>
                            </div>
                          </td>
                          <td className="ant-table-cell">
                            <Tag>{row.msg_type}:{row.version_no}</Tag>
                          </td>
                          <td className="ant-table-cell" style={{ padding: 0 }} colSpan={2}>
                            {(() => {
                              switch (row.msg_type) {
                                case "QR_INQUIRY_INFO": {
                                  return (
                                    <Description bordered>
                                      <Description.Item label="Төлбөрийн дүн" span={4}>{row.amount} {row.currency}</Description.Item>
                                      <Description.Item label="Гүйлгээний утга" span={4}>{row.description}</Description.Item>
                                      <Description.Item label="Хүлээн авах данс" span={4}>
                                        <Description bordered>
                                          {row.bank_accounts.map((row, index) => {
                                            return (
                                              <Description.Item key={index} label={row.bank_code} span={4}>
                                                <div>{row.account_name}</div>
                                                <Tag>{row.account_number}</Tag>
                                                {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                              </Description.Item>
                                            );
                                          })}
                                        </Description>
                                      </Description.Item>
                                    </Description>
                                  );
                                }
                                case "QR_PAY": {
                                  return (
                                    <Description bordered>
                                      {pRow?.client_type === "MERCHANT" && (
                                        <>
                                          <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                          <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                          <Description.Item label="Хүлээн авах данс" span={4}>
                                            <Description bordered>
                                              {pRow.bank_accounts?.map((row, index) => {
                                                return (
                                                  <Description.Item key={index} label={row.bank_code} span={4}>
                                                    <div>{row.account_name}</div>
                                                    <Tag>{row.account_number}</Tag>
                                                    {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                  </Description.Item>
                                                );
                                              })}
                                            </Description>
                                          </Description.Item>
                                        </>
                                      )}
                                      <Description.Item label="Нэхэмжлэл дугаар" span={2}>{row.invoice_code}</Description.Item>
                                      <Description.Item label="Төлбөрийн дугаар" span={2}>{row.payment_code}</Description.Item>
                                      <Description.Item label="Төлбөрийн төрөл" span={4}>{row.payment_method}</Description.Item>
                                    </Description>
                                  );
                                }
                                case "QR_PAID": {
                                  if (row.client_type === "MERCHANT")
                                    return (
                                      <Description bordered>
                                        <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                          <Description bordered>
                                            <Description.Item key={index} label="VISA" span={4}>
                                              <div>{pRow.cardholder_name}</div>
                                              <Tag>{pRow.pan}</Tag>
                                              <Tag color="black">{pRow.expiry}</Tag>
                                            </Description.Item>
                                          </Description>
                                        </Description.Item>
                                      </Description>
                                    );

                                  return (
                                    <Description bordered>
                                      {pRow?.client_type === "MERCHANT" && (
                                        <>
                                          <Description.Item label="Төлбөрийн дүн" span={4}>{pRow.amount} {pRow.currency}</Description.Item>
                                          <Description.Item label="Гүйлгээний утга" span={4}>{pRow.description}</Description.Item>
                                          <Description.Item label="Хүлээн авах данс" span={4}>
                                            <Description bordered>
                                              {pRow.bank_accounts?.map((row, index) => {
                                                return (
                                                  <Description.Item key={index} label={row.bank_code} span={4}>
                                                    <div>{row.account_name}</div>
                                                    <Tag>{row.account_number}</Tag>
                                                    {row.is_default && <Tag color="blue">Үндсэн</Tag>}
                                                  </Description.Item>
                                                );
                                              })}
                                            </Description>
                                          </Description.Item>
                                        </>
                                      )}
                                      <Description.Item label="Төлбөр төлсөн данс" span={4}>
                                        <Description bordered>
                                          <Description.Item key={index} label={row.bank_code} span={4}>
                                            <div>{row.bank_account_name}</div>
                                            <Tag>{row.bank_account_number}</Tag>
                                          </Description.Item>
                                        </Description>
                                      </Description.Item>
                                    </Description>
                                  );
                                }
                                default:
                              }
                            })()}
                          </td>
                          <td className="ant-table-cell">
                            <Tag>{row.message_status}</Tag>
                          </td>
                          <td className="ant-table-cell">{moment(row.message_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </>
              );
            }} />*/}
        </TableContainer>
      </PageContent>
    </PageContainer>
  );
};
const TableContainer = styled.div`
  overflow: auto;
  max-height: 1000px;
`;

const TableContainer2 = styled.div`
  /* overflow: auto;
  max-height: 34px; */
`;

const Table = styled(StickyHeaderCustomTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
  .sticky-header {
    position: sticky !important;
    top: 0;
    background: "#fafafa";
    z-index: 1;
  }
  .display-none {
    display: none
  }
`;
const Description = styled(Descriptions)`
  .ant-descriptions-view {
    border: 0;
  }
  .ant-descriptions-item-label, .ant-descriptions-item-content {
    padding: 8px!important;
  }
  .ant-descriptions-item-label {
    width: 200px;
  }
  .sticky-header {
    position: sticky !important;
    top: 0;
    background: "#fafafa";
    z-index: 1;
  }

  .display-none {
    display: none
  }

  .position-absolute {
    position: sticky,
  }
  .sticky-row-1 {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
  }
`;