import React from "react";
import { Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import {
  BarChartOutlined,
  LockOutlined,
  AimOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  ControlOutlined,
  BankOutlined,
  UserOutlined,
  ApiOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const { SubMenu } = Menu;

export default ({ isAdmin, isMobile }) => {
  const history = useHistory();
  let selected =
    (history.location.pathname.split("/")[1] || "") +
    "-" +
    (history.location.pathname.split("/")[2] || "");

    if (isAdmin) {
      return (
        <Container>
          <Menu
            defaultSelectedKeys={[selected]}
            mode="inline"
            theme="light"
            inlineCollapsed={isMobile}
          >
            <Menu.Item key="connection" icon={<ApiOutlined />}>
              Систем холболт <NavLink to="/connection" />
            </Menu.Item>
            <Menu.Item key="message" icon={<AimOutlined />}>
              Хүсэлтийн жагсаалт <NavLink to="/message" />
            </Menu.Item>
            <Menu.Item key="transaction" icon={<AimOutlined />}>
            Гүйлгээний мэдээлэл <NavLink to="/transaction" />
            </Menu.Item>
            {/* <SubMenu
              key="refund"
              title="Буцаалтын мэдээлэл"
              icon={<FileSyncOutlined />}
            >
              <Menu.Item key="refund-card">
                Карт гүйлгээ <NavLink to="/refund/card" />
              </Menu.Item>
              <Menu.Item key="refund-account">
                Данс гүйлгээ <NavLink to="/refund/account" />
              </Menu.Item>
            </SubMenu>*/}
            <SubMenu key="fee" title="Шимтгэл удирдлага" icon={<ControlOutlined />}>
              <Menu.Item key="fee-settings">
                Шимтгэл тохиргоо <NavLink to="/fee/settings" />
              </Menu.Item>
              <Menu.Item key="fee-calculation">
                Шимтгэл тооцоолол <NavLink to="/fee/calculation" />
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="operator"
              title="Харилцагч удирдлага"
              icon={<BankOutlined />}
            >
              <Menu.Item key="operator-list">
                Харилцагч <NavLink to="/operator/list" />
              </Menu.Item>
              <Menu.Item key="user-list-OPERATOR">
                Хэрэглэгч <NavLink to="/user/list/OPERATOR" />
              </Menu.Item>
            </SubMenu>

            <SubMenu key="report" title="Тайлан" icon={<ReconciliationOutlined />}>
              <Menu.Item key="report-monthly_bill">
                Харилцагчийн шимтгэл тооцоо нэхэмжлэх тайлан
                <NavLink to="/report/monthly_bill" />
              </Menu.Item>
              <Menu.Item key="/report/reconcilation">
                ePay-ийн баталгаажуулах тайлан <NavLink to="/report/reconcilation" />
              </Menu.Item>
              <Menu.Item key="/report/net_settlement">
                ePay нэгдсэн хаалтын тайлан <NavLink to="/report/net_settlement" />
              </Menu.Item>
              {/* <Menu.Item key="report-successful_trans">
                ePay бүх амжилттай гүйлгээний тайлан <NavLink to="/report/successful_trans" />
              </Menu.Item>
              <Menu.Item key="report-unsuccessful_trans">
                ePay бүх амжилтгүй гүйлгээний тайлан{" "}
                <NavLink to="/report/unsuccessful_trans" />
              </Menu.Item>*/}
              <Menu.Item key="/report/monthly/successful_trans">
                ePay-ийн нийлбэр амжилттай гүйлгээний тайлан{" "}
                <NavLink to="/report/monthly/successful_trans" />
              </Menu.Item>
              <Menu.Item key="/report/monthly/unsuccessful_trans">
                ePay-ийн нийлбэр амжилтгүй гүйлгээний тайлан{" "}
                <NavLink to="/report/monthly/unsuccessful_trans" />
              </Menu.Item>
            </SubMenu>

            <hr />

            <Menu.Item key="user-list-ADMIN" icon={<UserOutlined />}>
              Систем хэрэглэгч <NavLink to="/user/list/ADMIN" />
            </Menu.Item>
            <Menu.Item key="user-role" icon={<LockOutlined />}>
              Хандах эрх <NavLink to="/user/role" />
            </Menu.Item>
          </Menu>
        </Container>
      );
    }
    return (
      <Container>
        <Menu
          defaultSelectedKeys={[selected]}
          mode="inline"
          theme="light"
          inlineCollapsed={isMobile}
        >
          <Menu.Item key="connection" icon={<ApiOutlined />}>
            Систем холболт <NavLink to="/connection" />
          </Menu.Item>
          <Menu.Item key="transaction" icon={<AimOutlined />}>
          Гүйлгээний мэдээлэл <NavLink to="/transaction" />
          </Menu.Item>
          <SubMenu key="report" title="Тайлан" icon={<ReconciliationOutlined />}>
            <Menu.Item key="report-monthly_bill">
              Харилцагчийн шимтгэл тооцоо нэхэмжлэх тайлан
              <NavLink to="/report/monthly_bill" />
            </Menu.Item>
            <Menu.Item key="/report/reconcilation">
              ePay-ийн баталгаажуулах тайлан <NavLink to="/report/reconcilation" />
            </Menu.Item>
            <Menu.Item key="/report/net_settlement">
              ePay нэгдсэн хаалтын тайлан <NavLink to="/report/net_settlement" />
            </Menu.Item>
            <Menu.Item key="/report/monthly/successful_trans">
              ePay-ийн нийлбэр амжилттай гүйлгээний тайлан{" "}
              <NavLink to="/report/monthly/successful_trans" />
            </Menu.Item>
            <Menu.Item key="/report/monthly/unsuccessful_trans">
              ePay-ийн нийлбэр амжилтгүй гүйлгээний тайлан{" "}
              <NavLink to="/report/monthly/unsuccessful_trans" />
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Container>
    );
};

const Container = styled.div`
  z-index: 1000;
  padding-top: 20px;
  .project {
    padding: 20px;
    width: 100%;
    &.mobile {
      .ant-btn-group {
        .ant-btn:first-child {
          display: none !important;
        }
      }
    }
    .ant-btn-group {
      width: 100%;
      .ant-btn:last-child {
        width: 39px !important;
      }
      .ant-btn:first-child {
        width: 100%;
      }
    }
  }
  .ant-menu {
    border-right: none;

    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;
