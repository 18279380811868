import React from "react";
import { Table } from "antd";

const NetSettlementTotalTable = ({ data }) => {
  const [items, setItems] = React.useState([]);

  const columns = [
    {
      title: "Кредит дүн",
      dataIndex: "creditAmount",
      key: "creditAmount",
      render: (creditAmount) => <div style={{ fontWeight: "bold" }}>{creditAmount}</div>
    },
    {
      title: "Дебит дүн",
      dataIndex: "debitAmount",
      key: "debitAmount",
      render: (debitAmount) => <div style={{ fontWeight: "bold" }}>{debitAmount}</div>,
    },
  ];

	function formatter(value) {
		if (value > 0) {
			const parts = value.split(".");
			const intPart = parts[0];
			const decPart = parts[1];

			let formattedIntPart = "";
			for (let i = 0; i < intPart.length; i++) {
					if (i > 0 && (intPart.length - i) % 3 === 0) {
							formattedIntPart += ",";
					}
					formattedIntPart += intPart[i];
			}
			return `${formattedIntPart}.${decPart}`;
		} return null;
	};

  React.useEffect(() => {
    if (data) {
      setItems([
        {
          key: "1",
          creditAmount: formatter(data.creditAmountFloat),
          debitAmount: formatter(data.debitAmountFloat),
        },
      ]);
    };
  }, [data]);

  return (
    <Table
      columns={columns}
      bordered
      dataSource={items}
      pagination={false}
    />
  );
};

export default NetSettlementTotalTable;