import React from "react";
import { Table } from "antd";
import moment from "moment";

const NetSettlementTable = (
  {
    paricipment,
    loadData,
    data,
    initialLoading,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    options,
    tableLoading,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) {
        onResult(res);
      };
      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  React.useEffect(() => {
    const listData = [];
    if (data != null && data.length > 0) {
      listData.push({
        paricipment : paricipment,
        debitAmount : data[0].debitAmount,
        creditAmount: data[0].creditAmount,
      });
      listData.push({
        paricipment : "Нийт дүн",
        debitAmount : data[0].debitAmount,
        creditAmount: data[0].creditAmount,
      });
      setItems(listData);
      setLoading(false);
    }
  }, [value, data]);

  const useHeader = () => {
    const onLastCell = (_, index) => {
      return {
        className: items.length - 1 === index ? "header-cell" : "",
      };
    };

    return [
      {
        title   : "Хаалтын хамрах үе",
        children: [
          {
            title : "Хаалтын оролцогч",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.paricipment}</div>;
            },
            onCell: onLastCell,
          },
        ],
      },
      {
        title   : `Эхэлсэн цаг ${filters.startDate && moment(filters.startDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Кредит дүн",
            render: (record) => {
              return <div style={{ padding: "8px" }}>{record.creditAmount}</div>;
            },
            onCell: onLastCell,
          },
        ],
        // onCell: sharedOnCell,
      },
      {
        title   : `Дууссан цаг ${filters.endDate && moment(filters.endDate).format("YYYY-MM-DD")}`,
        children: [
          {
            title : "Дебит дүн",
            render: (record) => {
              return (
                <div style={{ padding: "8px" }}>{record.debitAmount}</div>
              );
            },
            onCell: onLastCell,
          },
        ],
        // onCell: sharedOnCell,
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
    </>
  );
};

export default React.forwardRef(NetSettlementTable);
