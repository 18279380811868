import React from "react";
import { Table } from "antd";
import dayjs from "dayjs";

const messageTable = (
	{
		data,
	}
) => {
	const [items, setItems] = React.useState();
	const listData = [];

	React.useEffect(() => {
		if (!data) return;
		data.forEach(e => {
			switch (e.msgType) {
				case "QR_INQUIRY":
					const trxInfo = {
						customerName: e.trxInfo.customerName,
						trxId: e.trxInfo.trxId
					};
					listData.push({
						id: e._id,
						column1: e.msgType,
						column2: e.trxInfo.trxId,
						column3: e.trxInfo.createdAt ? dayjs(e.trxInfo.createdAt).format("DD/MM/YYYY") : null,
						column4: JSON.stringify(trxInfo),
					});
					setItems(listData);
					break;
				case "QR_INQUIRY_INFO":
					const trxINfo = {
						amount: e.trxInfo.amount,
						transactionType: e.trxInfo.transactionType,
						description: e.trxInfo.description
					};
					listData.push({
						id: e._id,
						column1: e.msgType,
						column2: e.trxInfo.trxId,
						column3: e.trxInfo.createdAt ? dayjs(e.trxInfo.createdAt).format("DD/MM/YYYY") : null,
						column4: JSON.stringify(trxINfo)
					});
					setItems(listData);
					break;
				case "QR_PAID":
					const trxINFo = {
						customerName: e.trxInfo.customerName,
						amount: e.trxInfo.amount,
						description: e.trxInfo.description
					};
					listData.push({
						id: e._id,
						column1: e.msgType,
						column2: e.trxInfo.trxId,
						column3: e.trxInfo.createdAt ? dayjs(e.trxInfo.createdAt).format("DD/MM/YYYY") : null,
						column4: JSON.stringify(trxINFo)
					});
					setItems(listData);
					break;
				default:
					break;
			};
		});
	}, [data]);

	const useHeader = () => {
    return [
      {
        title : "msgType",
        render: (record) => {
          return (
						<div style={{ padding: "5px" }}>{record.column1}</div>
					);
        },
      },
      {
        title : "trxId",
        render: (record) => {
          return (
            <div style={{ padding: "5px" }}>{record.column2}</div>
          );
        },
      },
			{
        title : "createdAt",
        render: (record) => {
          return (
            <div style={{ padding: "5px" }}>{record.column3}</div>
          );
        },
      },
			{
        title : "trxInfo",
        render: (record) => {
          return (
            <div style={{ padding: "5px" }}>{record.column4}</div>
          );
        },
      },
    ];
  };

	return <Table
		{...{
			bordered  : true,
			columns   : useHeader({}),
			dataSource: items,
		}}
		rowKey={(record) => record?.id}
	/>;
};

export default messageTable;