import React from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

export default ({ dateFilter, isClear, value }) => {
  const initialDateFilter = {
    startDate: null,
    endDate: null,
  };

  const [dateFilters, setDateFilters] = React.useState(initialDateFilter);
  const filter = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setDateFilters({
        startDate: startDate ? dayjs(startDate).startOf("day").format("YYYY-MM-DDTHH:mm") : null,
        endDate: endDate ? dayjs(endDate).endOf("day").format("YYYY-MM-DDTHH:mm") : null,
      });
    } else {
      setDateFilters(initialDateFilter);
    }
  };

  const disabledDate = (current) => {
    const startDate = dateFilters.startDate ? dayjs(dateFilters.startDate) : null;
    const endDate = dateFilters.startDate ? dayjs(dateFilters.endDate) : null;
		if (!startDate) {
      return false;
    }
    const tooLate = startDate && current.diff(startDate, "days") > 30;
    const tooEarly = endDate && endDate.diff(current, "days") > 30;
    return !!tooEarly || !!tooLate;
  };

  React.useEffect(() => {
		if (dateFilters.startDate && dateFilters.endDate && value) {
			dateFilter(dateFilters);
		} else {
			dateFilter(initialDateFilter);
		}
  }, [dateFilters, value]);

	React.useEffect(() => {
		if (isClear) setDateFilters(initialDateFilter);
	}, [isClear]);

  return (
    <Space direction="vertical">
      <DatePicker.RangePicker
        key="DateFilter"
        value={[
          dateFilters.startDate ? dayjs(dateFilters.startDate) : null,
          dateFilters.endDate ? dayjs(dateFilters.endDate) : null,
        ]}
        onCalendarChange={filter}
        disabledDate={disabledDate}
      />
    </Space>
  );
};
