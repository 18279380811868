import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

const RowAction = ({ width, icon, actions, onClick }) => {
  const items = Object.keys(actions)
  .filter((key) => actions[key]) // Filter out disabled actions
  .map((key) => ({
    key, // Unique key for each menu item
    label  : actions[key], // The display label for the menu item
    onClick: () => onClick(key), // Attach the click handler
  }));

  return (
    <Dropdown
      disabled={items.length === 0} // Disable if no items are available
      menu={{ items }} // Use the new `menu` object structure
      trigger={["click"]}
    >
      <Button>
        {icon && icon} Үйлдэл
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default RowAction;
