import React from "react";
import { PageHeader as AntPageHeader } from "antd";
import styled from "styled-components";

const Content = styled.div`
  padding: 20px 24px;
  // box-shadow: 0 2px 14px 0 rgba(50, 50, 93, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
  border-radius: 4px;
  background: #fff;
  position: relative;
  // border: 1px solid #e8e8e8;
  width: 100%;
`;

export const PageContainer = (props) => {
  return (
    <Content {...props}/>
  );
};

export const PageHeader = styled(AntPageHeader)`
  padding: 20px 0px;
`;

export const PageContent = styled.div`
  
`;

export const TablleWrapper = styled.div `
  .thisMobile {  
    .ant-table-tbody > tr > td {
      border: none !important; 
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      display: none;
    }
  }
`;