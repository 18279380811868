import React from "react";
import { Table } from "antd";
import moment from "moment";

const MonthlyBillingTable = (
  {
    paricipment,
    loadData,
    data,
    pagination = true,
    rowExpandable,
    limit: initialLimit = 100,
    filters,
    items: rows,
    setHeaderIndexes,
    setSubheaderIndexes,
    perPage,
    getData,
    value,
    el,
    headerIndexes,
    subheaderIndexes,
    initialLoading,
    isSuccess,
    onTableChange = () => {},
    onResult = () => {},
    ...rest
  },
  ref
) => {
  const [loading, setLoading] = React.useState(initialLoading);
  const [items, setItems] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  // const [tableFilter, setTableFilter] = React.useState({});
  const [field, setField] = React.useState();
  const [sort, setSort] = React.useState();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(perPage);
  const [pageCount, setPageCount] = React.useState(1);
  const [totalEl, setTotalEl] = React.useState(1);

  const initialRenderPage = React.useRef(true);
  const initialRenderLimit = React.useRef(true);

  const [tableheaderIdx, setTableheaderIdx] = React.useState(headerIndexes);
  const [tablesubheaderIdx, setTablesubheaderIdx] = React.useState(setSubheaderIndexes);
  const [tabletotalheaderIdx, setTabletotalheaderIdx] = React.useState();

  const reload = React.useCallback(
    async (signal) => {
      if (!loadData) return;
      setLoading(true);

      const res = await loadData(
        {
          filter: {
            query: "",
            ...filters,
          },
          order: {
            [field]: sort,
          },
          offset: {
            page : page,
            limit: limit,
          },
        },
        { signal }
      );
      if (res.type === "error") return console.log("error", res.payload);

      setItems(
        res.rows.map((row, index) => ({
          ...row,
          rowKey: index + 1,
        }))
      );
      setTotal(res.count);

      if (onResult) onResult(res);

      setLoading(false);
    },
    [filters, limit, loadData, field, sort, page]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  React.useImperativeHandle(ref, () => ({
    reload() {
      reload();
    },
    setItems(items) {
      setItems(items);
    },
    getLoading() {
      return loading;
    },
    setLoading(loadStatus) {
      setLoading(loadStatus);
    },
    getPage() {
      return page;
    },
    getLimit() {
      return limit;
    },
  }));

  React.useEffect(() => {
    if (rows) {
      setItems(rows);
      setTotal(rows.length);
    }
  }, [rows]);

  React.useEffect(() => {
    let headerIdx = [];
    let subheaderIdx = [];
    let totalheaderIdx = [];
    const listData = [];
    if (data != null) {
      if (data.incoming.data.length > 0) {
        listData.push({
          date: `${paricipment} - Хүлээн авсан ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(0);

        data.incoming.data.forEach((item) => {
          const date = Object.keys(item)[0];
          const formattedDate = moment(date).format("YYYY-MM-DD");
          listData.push({
            ...item[date][0],
            date: formattedDate
          });
          listData.push({
            ...item.total[0],
            date  : `${formattedDate} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
          });
          subheaderIdx.push(listData.length - 1);
        });
        listData.push({
          date  : `${paricipment} ${isSuccess ? "Нийт хүлээн авсан дүн" : "Хүлээн авсан амжилтгүй дүн"}`,
          amount: data.incoming.totalAmount,
          charge: data.incoming.totalCharge,
          quantity: data.incoming.totalQuantity,
        });
        totalheaderIdx.push(listData.length - 1);
      }

      if (data.outgoing.data.length > 0) {
        listData.push({
          date: `${paricipment} - Илгээсэн ${!isSuccess ? "амжилтгүй" : ""} гүйлгээнүүд`,
        });
        headerIdx.push(listData.length - 1);

        data.outgoing.data.forEach((item) => {
          const date = Object.keys(item)[0];
          const formattedDate = moment(date).format("YYYY-MM-DD");
          listData.push({
            ...item[date][0],
            date: formattedDate
          });
          listData.push({
            ...item.total[0],
            date  : `${formattedDate} -ны нийт ${isSuccess ? "гүйлгээ" : "амжилтгүй дүн"}`,
          });
          subheaderIdx.push(listData.length - 1);
        });
        listData.push({
          date  : `${paricipment} ${isSuccess ? "Нийт илгээсэн дүн" : "Илгээсэн амжилтгүй дүн"}`,
          amount: data.outgoing.totalAmount,
          charge: data.outgoing.totalCharge,
          quantity: data.outgoing.totalQuantity,
        });
        totalheaderIdx.push(listData.length - 1);
      }

      setTableheaderIdx(headerIdx);
      setTablesubheaderIdx(subheaderIdx);
      setTabletotalheaderIdx(totalheaderIdx);
      setItems(listData);

      if (data.pagination) {
        if (
          data.pagination.paginationIncoming.total_elements >
          data.pagination.paginationOutgoing.total_elements
        ) {
          setTotal(data.pagination.paginationIncoming.total_elements);
        } else {
          setTotal(data.pagination.paginationOutgoing.total_elements);
        }

        if (
          data.pagination.paginationIncoming.total_pages >
          data.pagination.paginationOutgoing.total_pages
        ) {
          setPageCount(data.pagination.paginationIncoming.total_pages);
        } else {
          setPageCount(data.pagination.paginationOutgoing.total_pages);
        }

        setTotalEl(
          data.pagination.paginationOutgoing.total_elements +
            data.pagination.paginationIncoming.total_elements
        );
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (initialRenderPage.current) {
      initialRenderPage.current = false;
    } else {
      getData(el);
    }
  }, [page]);

  React.useEffect(() => {
    if (initialRenderLimit.current) {
      initialRenderLimit.current = false;
    } else {
      getData(el);
    }
  }, [limit]);

  const useHeader = ({ type, onAction, s3host }) => {
    const sharedOnCell = (_, index) => {
      if (tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index) || tabletotalheaderIdx.includes(index)) {
        return {
          colSpan: 0,
        };
      }
      return {
        colSpan: 1,
      };
    };

    const fixNest = (_, index) => {
      if (tableheaderIdx.includes(index)){
        return 6;
      }
      if (tablesubheaderIdx.includes(index)){
        return 5;
      }
      if (tabletotalheaderIdx.includes(index)){
        return 3;
      }
      return 1;
    };

    const fixNest2 = (_, index) => {
      if (tableheaderIdx.includes(index)){
        return "header-cell";
      }
      if (tablesubheaderIdx.includes(index)){
        return "subheader-cell";
      }
      if (tabletotalheaderIdx.includes(index)){
        return "subheader-cell";
      }
      return "cell-center";
    };

    const onHeaderCell = (_, index) => {
      let idx = fixNest(_, index);
      let classname = fixNest2(_, index);
      return {
        colSpan  : idx,
        className: classname
      };
    };

    return [
      {
        title : "Огноо",
        align : "left",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>
              {record.date}
            </div>
          );
        },
        onCell: onHeaderCell,
      },
      {
        title : "Систем",
        render: (record, index) => {
          return <div style={{ padding: "8px" }}>{record.system}</div>;
        },
        onCell: sharedOnCell,
      },
      {
        title : "Гүйлгээний төрөл",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>
              {record.type === "PURCHASE" ? "Худалдан авалт (данс)" : null}
            </div>
          );
        },
        onCell: sharedOnCell,
      },
      {
        title : "Гүйлгээний тоо",
        render: (record) => {
          return <div style={{ padding: "8px" }}>{record.quantity}</div>;
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0 : 1,
          className: tablesubheaderIdx.includes(index) || tabletotalheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
      {
        title : "Нэхэмжлэх шимтгэл дүн",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.charge}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan:
            tableheaderIdx.includes(index) || tablesubheaderIdx.includes(index)
              ? 0 : 1,
          className: tablesubheaderIdx.includes(index) || tabletotalheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
      {
        title : "Гүйлгээний нийт дүн",
        render: (record) => {
          return (
            <div style={{ padding: "8px" }}>{record.total_amount ? record.total_amount : record.amount}</div>
          );
        },
        onCell: (_, index) => ({
          colSpan  : tableheaderIdx.includes(index) ? 0 : 1,
          className: tablesubheaderIdx.includes(index) || tabletotalheaderIdx.includes(index) ? "subvalue-cell" : "",
        }),
      },
    ];
  };

  return (
    <>
      <Table
        {...{
          scroll    : { x: 100 },
          bordered  : true,
          columns   : useHeader({}),
          dataSource: items,
          rowExpandable,
          pagination: false,
          ...rest,
        }}
        loading={loading}
        rowKey={(record, index) => record.id || index}
      />
    </>
  );
};

export default React.forwardRef(MonthlyBillingTable);
