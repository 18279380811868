import React from "react";
import { Tag } from "antd";
import { connection as connectionApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { MyTable } from "../../components";
import styled from "styled-components";
import dayjs from "dayjs";

export default () => {
  const myTableRef = React.useRef();

  const [filters, setFilters] = React.useState({
    start_date: "",
    end_date  : ""
  });

  return (
    <PageContainer>
      <PageHeader title="Систем холболт" />
      <PageContent>
        <Table
          ref={myTableRef}
          columns={useHeader({})}
          filters={filters}
          loadData={connectionApi.stats}
          rowKey={(record) => record?.rowKey}/>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ type, onAction, s3host }) => {
  return [{
    title    : "№",
    dataIndex: "rowKey",
    width    : "20px"
  }, {
    title : "Харилцагч",
    render: (record) => {
      return (
        <div>
          <div style={{ marginBottom: 5 }}>{record.name}</div>
        </div>
      );
    }
  }, {
    title : "Status",
    render: (record) => {
      return record ? <Tag color="green">CONNECTED</Tag> : <Tag color="red">DISCONNECTED</Tag>;
    }
  }, {
    title : "Created Date",
    render: (record) => {
      return (<div>{record.created_at ? dayjs(record.created_at).format("YYYY-MM-DD , HH:mm:ss") : "-"}</div>);
    }
  }, {
    title : "Updated Date",
    render: (record) => {
      return (<div>{record.updated_at ? dayjs(record.updated_at).format("YYYY-MM-DD , HH:mm:ss") : "-"}</div>);
    }
  }];
};

const Table = styled(MyTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
  }
`;