import React from "react";
import { Tag, Button, DatePicker, Modal, Select, InputNumber, message } from "antd";
import { transaction as transacionApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { StickyHeaderCustomTable, RowAction } from "../../components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Filter } from "../../components/Design";
import styled from "styled-components";
import { tugrug } from "../../utils";
import RefundForm from "./RefundForm";
import moment from "moment";
import { operator as operatorApi } from "../../apis";

const TYPES = "BANK_ACCOUNT";

export default () => {
  const myTableRef=React.useRef();
  const [action, setAction]=React.useState([]);
  const params = useParams();
  const [options, setOptions]=React.useState([]);
  const [erembe, setErembe] = React.useState();

  const erembes = [
    { value: "ASC", label: "Хугацааны эхнээс" },
    { value: "DESC", label: "Хугацааны сүүлээс" }
  ];

  const [filter, setFilter]=React.useState({
    type: TYPES,
    start_date: "",
    end_date: "",
    operator_code_credit: null,
    operator_code_debit: null,
    amount_min: null,
    amount_max: null,
  });

  const [filters, setFilters]=React.useState({
    type: TYPES,
    start_date: "",
    end_date: "",
    operator_code_credit: null,
    operator_code_debit: null,
    amount_min: null,
    amount_max: null
  });

  const dateFilter=(e) => {
    setFilter({
      ...filter,
      start_date: e && moment.utc(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      end_date: e && moment.utc(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm")
    });
  };

  const dateFilter2=(e) => {
    setFilter({ ...filter, operator_code_credit: e });
  };
  const dateFilter3=(e) => {
    setFilter({ ...filter, operator_code_debit: e });
  };

  const onChangeMin=(value) => {
    setFilter({ ...filter, amount_min: value });
  };

  const onChangeMax=(value) => {
    setFilter({ ...filter, amount_max: value });
  };

  const onAction=(type, row) => {
    switch (type) {
      case "refund": {
        Modal.confirm({
          title: "Баталгаажуулах",
          icon: <ExclamationCircleOutlined />,
          content: "Та буцаалт хийхдээ итгэлтэй байна уу!!!",
          okText: "Буцаах",
          cancelText: "Болих",
          onOk: async () => {
            setAction(["refund", row]);

            myTableRef.current.reload();
          },
        });

        break;
      }
      default:
    }
  };

  const search=() => {
    setFilters(filter);
    if (filter.start_date === "" || filter.end_date === ""){
      message.error("Эхлэх болон дуусах өдрийг оруулна уу");
    };
  };

  const onCancel=(reload) => {
    setAction([]);

    if (reload)
      myTableRef.current.reload();
  };

  const onClear=() => {
    const tmp = {
      type: TYPES,
      start_date: "",
      end_date: "",
      operator_code_credit: null,
      operator_code_debit: null,
      amount_min: null,
      amount_max: null
    };
    setFilter(tmp);
    setFilters(tmp);
  };

  // React.useEffect(() => {
  //  if (filters.type !== TYPES[params.type])
  //    setFilters(state => ({
  //      ...state,
  //      type: TYPES[params.type]
  //    }));
  // }, [params.type]);

  React.useEffect(() => {
    operatorApi.list({
      offset: {
        page: 1,
        limit: 100
      },
      filter: {
        is_active: true,
      }
    }).then((res) => {
      if (res) {
        let listData=[];
        for (let index=0;index<res.rows.length;index++) {
          const selectedData=res.rows[index];
          selectedData.label=selectedData.name;
          selectedData.value=selectedData.code;
          selectedData.tableRef=React.createRef();
          listData.push(selectedData);
        }
        setOptions(listData);
      }
    });
  }, []);

  return (
    <PageContainer>
      <PageHeader title={"Гүйлгээний жагсаалт"} />
      <PageContent>
        <Filter
          extra={[
            <Button
              onClick={search}
            >
              Хайлт хийх
            </Button>,
            <Button
              onClick={onClear}
            >
              Цэвэрлэх
            </Button>
          ]}
        >
          <DatePicker.RangePicker
            value={[
              filter.start_date && moment(filter.start_date),
              filter.end_date && moment(filter.end_date),
            ]}
            onChange={dateFilter}
          />
          <Select
            style={{ width: "200px" }}
            options={options}
            placeholder="Харилцагч"
            maxTagCount="responsive"
            value={filter.operator_code_credit}
            onChange={(val) => {
              dateFilter2(val);
            }}
            optionRender={(option) => {
              return (
                <Space>
                  <span role="img" aria-label={option.regUserId}>
                    {option.regUserId}
                  </span>
                </Space>
              );
            }}
          />
          <Select
            // mode="multiple"
            style={{ width: "200px" }}
            options={options}
            placeholder="Хүлээн авагч"
            maxTagCount="responsive"
            value={filter.operator_code_debit}
            onChange={(val) => {
              dateFilter3(val);
            }}
            optionRender={(option) => {
              return (
                <Space>
                  <span role="img" aria-label={option.regUserId}>
                    {option.regUserId}
                  </span>
                </Space>
              );
            }}
          />
          <Select
            style={{ width: "200px" }}
            options={erembes}
            placeholder="Эрэмбэ"
            maxTagCount="responsive"
            value={erembe}
            onChange={(val) => {
              setErembe(val);
            }}
          />
          <InputNumber placeholder="Бага дүн" min={1} defaultValue={null} value={filter.amount_min} onChange={onChangeMin} />
          <InputNumber placeholder="Их дүн" min={1} defaultValue={null} value={filter.amount_max} onChange={onChangeMax} />
        </Filter>
        <TableContainer>
          <Table
            ref={myTableRef}
            filters={filters}
            erembe={erembe}
            loadData={transacionApi.list}
            thead={() => (
              <>
                <thead className="ant-table-thead sticky">
                  <tr>
                    <th className="ant-table-cell">№</th>
                    <th className="ant-table-cell">Төлбөр илгээгч харилцагч</th>
                    <th className="ant-table-cell">Төлбөр илгээгчийн данс</th>
                    <th className="ant-table-cell">Төлбөр илгээгчийн нэр</th>
                    <th className="ant-table-cell">Төлбөр хүлээн авагч харилцагч</th>
                    <th className="ant-table-cell">Төлбөр хүлээн авагчын данс</th>
                    <th className="ant-table-cell">Төлбөр хүлээн авагчын нэр</th>
                    {/* <th className="ant-table-cell">Төлсөн данс</th>
                    <th className="ant-table-cell">Хүлээн авах данс</th>*/}
                    <th className="ant-table-cell">Дүн</th>
                    <th className="ant-table-cell">Гүйлгээний утга</th>
                    <th className="ant-table-cell">Гүйлгээний нөхцөл</th>
                    {/* <th className="ant-table-cell">Хүлээн авагч шимтгэл</th>
                    <th className="ant-table-cell">Илгээгч шимтгэл</th>*/}
                    <th className="ant-table-cell">Монгол банк шимтгэл</th>
                    <th className="ant-table-cell">ЭПЭЙ шимтгэл</th>
                    <th className="ant-table-cell">Төлөв</th>
                    <th className="ant-table-cell">Огноо</th>
                  </tr>
                </thead>
              </>
            )} tbody={(row, index, page, limit) => {
              let customIndex=(page*limit)+index-limit+1;
              return (
                <>
                  <div style={{ height: "0.5px" }} />
                  <tbody key={index} className="ant-table-tbody" style={{ borderTop: "1px solid black" }}>
                    <tr>
                      <td className="ant-table-cell" rowSpan={2}>{customIndex}</td>
                      <td className="ant-table-cell">
                        {row.debit.operator_name}
                      </td>
                      <td className="ant-table-cell">
                        {row.paid.bank_account_number}
                      </td>
                      <td className="ant-table-cell">
                        {row.paid.bank_account_name}
                      </td>
                      <td className="ant-table-cell">
                        {row.credit.operator_name}
                      </td>
                      <td className="ant-table-cell">
                        {row.beneficiary.bank_account_number}
                      </td>
                      <td className="ant-table-cell">
                        {row.beneficiary.bank_account_name}
                      </td>
                      <td className="ant-table-cell">
                        {tugrug(row.amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 200 }}>
                        {row.description}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {row.payment_method === "BANK_ACCOUNT" ? "Данс" : "Карт"}
                      </td>
                      {/* <td className="ant-table-cell fee">
                        <div className="divide" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                          <span>{row.fee.acquirer_fee_type==="PERCENT"&&row.fee.acquirer_fee+"%"}</span>
                          <span>{tugrug(row.fee.acquirer_fee_amount)}</span>
                        </div>
                      </td>
                      <td className="ant-table-cell fee">
                        <div className="divide" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                          <span>{row.fee.issuer_fee_type==="PERCENT"&&row.fee.issuer_fee+"%"}</span>
                          <span>{tugrug(row.fee.issuer_fee_amount)}</span>
                        </div>
                      </td>*/}
                      <td className="ant-table-cell fee">
                        <div className="divide" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                          <span>{row.fee.bom_fee_type==="PERCENT"&&row.fee.bom_fee+"%"}</span> <br/>
                          <span>{tugrug(row.fee.bom_fee_amount)}</span>
                        </div>
                      </td>
                      <td className="ant-table-cell fee">
                        <div className="divide" style={{ justifyContent: "space-between", flexDirection: "column" }}>
                          <span>{row.fee.epay_fee_type==="PERCENT"&&row.fee.epay_fee+"%"}</span>
                          <span>{tugrug(row.fee.epay_fee_amount)}</span>
                        </div>
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        <Tag color="green">{row.transaction_status}</Tag>
                      </td>
                      <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 180 }}>
                        {moment(row.transaction_status_date).format("YYYY-MM-DD HH:mm:ss")}
                      </td>

                    </tr>
                  </tbody>
                </>
              );
            }}
          />
        </TableContainer>
      </PageContent>

      {/* <Modal
        title="Буцаалт хийх"
        visible={action&&action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <RefundForm onCancel={onCancel} action={action} />
      </Modal>*/}
    </PageContainer>
  );
};

const TableContainer=styled.div`
  overflow: auto;
  max-height: 1000px;
`;

const TableContainer2=styled.div`
  overflow: auto;
  max-height: 65px;
`;

const Table=styled(StickyHeaderCustomTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
    &.fee {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .divide {
    display: flex;
  }
`;